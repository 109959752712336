import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import decode from 'jwt-decode'

import Login from './Components/Users/Login'
import Register from './Components/Users/Register'
import NavBar from './Components/Navigation/NavBar'
import Dashbord from './Components/Dashbord/Dashbord'
import Profile from './Components/Users/Profile'
import Lab from './Components/Products/Lab'
//Admin
import Admin from './Components/Admin/Admin'
import AddProductGroup from './Components/Admin/AddProductGroup'
import AddProductDetail from './Components/Admin/AddProductDetail'
import AddProductItem from './Components/Admin/AddProductItem'
import AddTracking from './Components/Admin/AddTracking'

//Report 
import Reports from './Components/Reports/Reports'
import WarehouseReportByRoll from './Components/Reports/Warehouse/ByRoll'
import WarehouseReportByOrders from './Components/Reports/Warehouse/ByRoll'

//Charts
import Charts from './Components/Charts/Charts'
import SellQtyByMonthBySales from './Components/Charts/SellQtyByMonthBySales'
import SellAmountSalesCompareByYear from './Components/Charts/SellAmountSalesCompareByYear'

//MonileApp
import AddProductList from './Components/MobileApp/AddProductList'

import { connect } from 'react-redux'
import { setUserEmail, setUserId, setUserIsLogin, setUserMobile, setUserName } from './Redux/Actions/Users/LoginActions'

class App extends Component {

  componentDidMount = () => {
    this.checkUser()
    this.checkIsUser()
  }

  checkIsUser = async () => {
    // if(this.props.Users)
    try {
      const token = await localStorage.getItem('KPMTEXTILE_BACKOFFICE_TOKEN')
      if (token === null) {
        console.log('Token is null')
        this.props.setUserIsLogin(false)
      } else {
        const user = decode(token)
        this.props.setUserIsLogin(true)
        this.props.setUserId(user.id)
        this.props.setUserName(user.fullname)
        this.props.setUserEmail(user.email)
        this.props.setUserMobile(user.mobile)
        // console.log(user)
      }
    } catch (error) {
      console.log(error)
    }
  }

  checkUser = () => {
    if (localStorage.getItem('KPMTEXTILE_BACKOFFICE_TOKEN') === null) {
      return false
    } else {
      return true
    }
  }

  PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      // this.props.Users.userIsLogin
      this.checkUser()
        ? <Component {...props} />
        : <Redirect to={'/login'} />
    )} />
  )

  render() {
    return (
      <div>
        <Router>
          <Route component={NavBar} />
          <Switch>
            <Route path='/login' exact component={Login} />
            <Route path='/register' component={Register} />
            <this.PrivateRoute path='/' exact component={Dashbord} />
            <this.PrivateRoute path='/profile' component={Profile} />
            <this.PrivateRoute path='/lab' component={Lab} />
            <this.PrivateRoute path='/admin' component={Admin} />
            <this.PrivateRoute path='/addtracking' component={AddTracking} />
            <this.PrivateRoute path='/addproductgroup' component={AddProductGroup} />
            <this.PrivateRoute path='/addproductdetail' component={AddProductDetail} />
            <this.PrivateRoute path='/addproductitem' component={AddProductItem} />
            <this.PrivateRoute path='/report' component={Reports} />
            <this.PrivateRoute path='/getwarehousereportbyroll' component={WarehouseReportByRoll} />
            <this.PrivateRoute path='/getwarehousereportbyorders' component={WarehouseReportByOrders} />
            <this.PrivateRoute path='/charts' component={Charts} />
            <this.PrivateRoute path='/sellqtybymonthbysales' component={SellQtyByMonthBySales} />
            <this.PrivateRoute path='/sellamountsalescomparebyear' component={SellAmountSalesCompareByYear} />
            <this.PrivateRoute path='/addproductlist' component={AddProductList} />
          </Switch>
        </Router>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  Users: state.Users
})

const mapDispatchToProps = (dispatch) => ({
  setUserEmail: (email) => {
    dispatch(setUserEmail(email))
  },
  setUserId: (id) => {
    dispatch(setUserId(id))
  },
  setUserIsLogin: (islogin) => {
    dispatch(setUserIsLogin(islogin))
  },
  setUserMobile: (mobile) => {
    dispatch(setUserMobile(mobile))
  },
  setUserName: (name) => {
    dispatch(setUserName(name))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(App)