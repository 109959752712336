import React, { useState, useEffect } from 'react'
import Axios from '../../Services/Axios'
import { Container, Spinner, Modal, Collapse, ButtonGroup, Button, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Input } from 'reactstrap'
import { localToken as token } from '../../Services/Document'
import { FaSearch, FaArrowLeft, FaPlus, FaTrash } from 'react-icons/fa'
import dayjs from 'dayjs'
import AddPriceListComponents from './AddPriceListComponents'
import AddCostListComponents from './AddCostListComponents'
import AddProductListComponents from './AddProductListComponents'
import ModalHeader from 'reactstrap/lib/ModalHeader'
import ModalFooter from 'reactstrap/lib/ModalFooter'

const AddProductList = (props) => {
    const [productListData, setProductListData] = useState([])
    const [productListLoading, setProductListLoading] = useState(false)
    const [filterText, setFilterText] = useState('%')
    const [productWindowIsOpen, setProductWindowIsOpen] = useState(true)
    const [priceListData, setPriceListData] = useState([])
    const [costListData, setCostListData] = useState([])
    const [priceWindowIsOpen, setPriceWindowIsOpen] = useState(false)
    const [priceListLoading, setPriceListLoading] = useState(false)
    const [costListLoading, setCostListLoading] = useState(false)
    const [addCostListIsOpen, setAddCostListIsOpen] = useState(false)
    const [addPriceListIsOPen, setAddPriceListIsOPen] = useState(false)
    const [addProductListIsOpen, setAddProductListIsOpen] = useState(false)
    const [selectedCode, setSelectedCode] = useState(null)
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const [selectedId, setSelectedId] = useState(null)
    const [deleteCostIsOpen, setDeleteCostIsOpen] = useState(false)
    const [deletePriceListIsOpen, setDeletePriceListIsOpen] = useState(false)
    const [selectCostListId, setSelectCostListId] = useState(null)
    const [selectPricelistId, setSelectPricelistId] = useState(null)

    useEffect(() => {
        getProductListData()
    }, [filterText])

    const getProductListData = async () => {
        setProductListLoading(true)
        await Axios({
            method: 'POST',
            url: '/getproductlistmenu',
            headers: {
                Authorization: 'Bearer ' + token
            }, data: {
                code: filterText
            }
        }).then((res) => {
            // console.log(res.data.data[0])
            setProductListData(res.data.data[0])
            setProductListLoading(false)
        }).catch((err) => {
            console.log(err)
            setProductListLoading(false)
        })
    }

    const getPriceListData = async (e) => {
        setPriceListLoading(true)
        setTimeout(async () => {
            await Axios({
                method: 'POST',
                url: '/getpricelistall',
                data: {
                    code: selectedCode !== e ? e : selectedCode
                },
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then((res) => {
                // console.log(res.data.data[0])
                setPriceListData(res.data.data[0])
                setPriceListLoading(false)
            }).catch((err) => {
                console.log(err)
                setPriceListLoading(false)
            })
        }, 500)
    }

    const getCostListData = async (e) => {
        setCostListLoading(true)
        setTimeout(async () => {
            await Axios({
                method: 'POST',
                url: '/getproductpricedetail',
                data: {
                    article: selectedCode !== e ? e : selectedCode
                },
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then((res) => {
                // console.log('Cost : ', res.data.data[0])
                setCostListLoading(false)
                setCostListData(res.data.data[0])
            }).catch((err) => {
                console.log(err)
                setCostListLoading(false)
            })
        }, 500)
    }

    const deleteProductList = async () => {
        Axios({
            method: 'DELETE',
            url: '/deleteproductslist',
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: {
                id: selectedId
            }
        }).then((res) => {
            // console.log(res.data)
            setDeleteModalIsOpen(false)
            getProductListData(selectedCode)
            setProductWindowIsOpen(true)
            setPriceWindowIsOpen(false)
        }).catch((err) => {
            console.log(err)
        })
    }

    const deleteCostList = async () => {
        if (selectCostListId === null) {
            alert('Please Select Cost!!!')
        } else {
            Axios({
                method: 'DELETE',
                url: '/deletecostlistdetail',
                data: {
                    id: selectCostListId
                },
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then((res) => {
                // console.log(res)
                getCostListData(selectedCode)
                setDeleteCostIsOpen(false)
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    const deletePriceList = async () => {
        if (selectPricelistId === null) {
            alert('Please Select Cost!!!')
        } else {
            Axios({
                method: 'DELETE',
                url: '/deletepricelist',
                data: {
                    id: selectPricelistId
                },
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then((res) => {
                // console.log(res)
                getPriceListData(selectedCode)
                setDeletePriceListIsOpen(false)
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    return (
        <Container>
            <Collapse isOpen={productWindowIsOpen} >
                <ListGroup>
                    <ListGroupItem>
                        <ButtonGroup style={{ left: 0, right: 0, width: '100%' }} >
                            <Input plachholder='Search' onChange={(e) => { setFilterText(e.target.value) }} />
                            <Button style={{ minWidth: 100 }} onClick={getProductListData} outline ><FaSearch style={{ fontSize: 22 }} /></Button>
                            <Button outline onClick={() => { setAddProductListIsOpen(true) }} ><FaPlus style={{ fontSize: 22, Color: 'red' }} /></Button>
                        </ButtonGroup>
                    </ListGroupItem>
                    {productListLoading ? (<ListGroupItem style={{ display: 'flex', justifyContent: 'center' }}><Spinner /></ListGroupItem>) : (
                        productListData.map((rows, index) => (
                            <ListGroupItem tag='button' key={index}
                                style={{ display: 'flex', flexDirection: 'column' }}
                                onClick={() => {
                                    setSelectedCode(rows.code)
                                    setSelectedId(rows.id)
                                    getPriceListData(rows.code)
                                    getCostListData(rows.code)
                                    setProductWindowIsOpen(false)
                                    setPriceWindowIsOpen(true)
                                }} >
                                <ListGroupItemHeading>{rows.code + (rows.name == null ? '' : ' / ' + rows.name)}</ListGroupItemHeading>
                                <div>{(rows.construction !== null ? rows.construction + '/' : '')
                                    + (rows.density !== null ? rows.density + ' ' : '')
                                    + (rows.weaving !== null ? rows.weaving + ' ' : '')
                                    + (rows.finishing !== null ? rows.finishing : '')} </div>
                                <div>{rows.weight !== null ? ('Weight : ' + rows.weight + 'GMS') : ('')}</div>
                            </ListGroupItem>
                        ))
                    )}
                </ListGroup>
            </Collapse>
            <Collapse isOpen={priceWindowIsOpen} >
                <Button outline onClick={() => {
                    setPriceWindowIsOpen(false)
                    setProductWindowIsOpen(true)
                }} > <FaArrowLeft style={{ fontSize: 20 }} /> Back</Button>
                <Button outline onClick={() => { setDeleteModalIsOpen(true) }} ><FaTrash style={{ fontSize: 22, color: 'red' }} /> Delete Product </Button>
                <ListGroup>
                    <ListGroupItem >
                        <ListGroupItemHeading style={{ color: 'red', fontSize: 30 }} >Sales Price</ListGroupItemHeading>
                        <ListGroupItemText >
                            <Button outline onClick={() => { setAddPriceListIsOPen(true) }} ><FaPlus style={{ fontSize: 20 }} /> New Sales Price</Button>
                        </ListGroupItemText>
                    </ListGroupItem>
                    {priceListLoading ? (<Spinner />) : (
                        priceListData.map((rows, index) => (
                            <ListGroupItem key={index} >
                                <ListGroupItemHeading>Article : {rows.code + '  /  ' + rows.name}</ListGroupItemHeading>
                                <ListGroupItemText>Width : {rows.width}</ListGroupItemText>
                                <ListGroupItemText>Min Price - Sales Price (Shade) : {rows.min_price + '-' + rows.sales_price + ' (' + rows.color_shade + ')'}</ListGroupItemText>
                                <ListGroupItemText>Create Date - Last Update : {rows.create_date + '-' + rows.update_date}</ListGroupItemText>
                                <ListGroupItemText>Conditions : {rows.conditions}</ListGroupItemText>
                                <ListGroupItemText style={{ width: '100%' }} >
                                    <Button outline style={{ width: '100%' }} onClick={() => {
                                        setDeletePriceListIsOpen(true)
                                        setSelectPricelistId(rows.id)
                                    }}>
                                        <FaTrash style={{ fontSize: 20, color: 'red' }} /> Delete
                                    </Button>
                                </ListGroupItemText>
                            </ListGroupItem>))
                    )}
                    <ListGroupItem style={{ fontSize: 30, color: 'red' }} >Cost
                        <ListGroupItemText >
                            <Button outline onClick={() => { setAddCostListIsOpen(true) }}><FaPlus style={{ fontSize: 20 }} /> New Cost</Button>
                        </ListGroupItemText>
                    </ListGroupItem>
                    {costListLoading ? (<Spinner />) : (
                        costListData.map((rows, index) => (
                            <ListGroupItem key={index} >
                                <ListGroupItemHeading>Arcticle :{rows.product_article}</ListGroupItemHeading>
                                <ListGroupItemText>Shade : {rows.color_shade}</ListGroupItemText>
                                <ListGroupItemText>Cost Bf Vat : {rows.cost_bf_vat}</ListGroupItemText>
                                <ListGroupItemText>Cost Af Vat : {rows.cost_af_vat}</ListGroupItemText>
                                <ListGroupItemText>Cost Af Cost 6% : {rows.cost_af_cost}</ListGroupItemText>
                                <ListGroupItemText>Last Update : {dayjs(rows.update_date).format('DD/MM/YYYY')}</ListGroupItemText>
                                <ListGroupItemText style={{ width: '100%' }} >
                                    <Button outline style={{ width: '100%' }} onClick={() => {
                                        setDeleteCostIsOpen(true)
                                        setSelectCostListId(rows.id)
                                    }} >
                                        <FaTrash style={{ fontSize: 20, color: 'red' }} /> Delete
                                    </Button>
                                </ListGroupItemText>
                            </ListGroupItem>)))}
                </ListGroup>
            </Collapse>
            <Modal isOpen={addPriceListIsOPen}>
                <AddPriceListComponents setAddPricelistIsOpen={setAddPriceListIsOPen} getPriceListData={getPriceListData} selectedCode={selectedCode} />
            </Modal>
            <Modal isOpen={addCostListIsOpen}>
                <AddCostListComponents selectedCode={selectedCode} setAddCostListIsOpen={setAddCostListIsOpen} getCostListData={getCostListData} />
            </Modal>
            <Modal isOpen={addProductListIsOpen} >
                <AddProductListComponents setAddProductListIsOpen={setAddProductListIsOpen} getPriceListData={getPriceListData} />
            </Modal>
            <Modal isOpen={deleteModalIsOpen}>
                <ModalHeader>Are Your Sure,Delete This Product?</ModalHeader>
                <ModalFooter>
                    <Button onClick={() => { deleteProductList() }} outline>Yes</Button>
                    <Button onClick={() => { setDeleteModalIsOpen(false) }} outline >Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={deleteCostIsOpen} >
                <ModalHeader>Are Your Sure,Delete This Cost?</ModalHeader>
                <ModalFooter>
                    <Button onClick={() => { deleteCostList() }} outline>Yes</Button>
                    <Button onClick={() => { setDeleteCostIsOpen(false) }} outline >Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={deletePriceListIsOpen} >
                <ModalHeader>Are Your Sure,Delete This Price List?</ModalHeader>
                <ModalFooter>
                    <Button onClick={() => { deletePriceList() }} outline>Yes</Button>
                    <Button onClick={() => { setDeletePriceListIsOpen(false) }} outline >Cancel</Button>
                </ModalFooter>
            </Modal>
        </Container>
    )
}

export default AddProductList
