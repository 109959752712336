import { GET_PRODUCT_ITEM_DATA_ALL,GET_PRODUCT_DETAIL_DATA_ALL } from '../../Type'

const productItemReducers = (state = {
    productItemDataAll: [],
    productDetailDataAll:[]
}, action) => {
    switch (action.type) {
        case GET_PRODUCT_ITEM_DATA_ALL:
            state = {
                ...state,
                productItemDataAll: action.playload
            }
            break
            case GET_PRODUCT_DETAIL_DATA_ALL:
            state = {
                ...state,
                productDetailDataAll: action.playload
            }
            break
        default:
    }
    return state
}

export default productItemReducers
