import React, { Component } from 'react'
import { Container, InputGroup, InputGroupAddon, Input, Button, Card, CardBody, Spinner, CardHeader, Table, Modal, Row, ModalBody, ModalFooter,ModalHeader } from 'reactstrap'
import Axios from '../../Services/Axios'
import dayjs from 'dayjs'
import { FaSearch, FaTrash } from 'react-icons/fa'
// import DataTable from 'react-data-table-component'

export default class AddTracking extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: null,
            shipDate: null,
            name: null,
            shipBy: null,
            trackingNo: null,
            detail: null,
            trackingData: [],
            keyword: '%',
            isLoading: true,
            modalIsOpen: false,
            confirmDeleteIsOpen: false,
            deleteId: null
        }
    }

    componentDidMount = async () => {
        this.getTrackingAll()
    }

    onAddTracking = () => {
        if (
            this.state.shipDate === null
            || this.state.name === null
            || this.state.shipBy === null
            || this.state.trackingNo === null
        ) {
            alert('กรุณาใส่ข้อมูลให้ครบ')
        } else {
            Axios({
                method: 'POST',
                url: '/addtracking',
                data: {
                    id: this.state.id,
                    shipDate: this.state.shipDate,
                    name: this.state.name,
                    shipBy: this.state.shipBy,
                    trackingNo: this.state.trackingNo,
                    detail: this.state.detail
                }
            }).then((res) => {
                this.getTrackingAll()
            }).catch((err) => {
                alert(err)
                console.log(err)
            })
        }
    }

    getTrackingAll = async () => {
        await Axios({
            method: 'POST',
            url: '/gettrackingall',
            data: {
                keyword: this.state.keyword
            }
        }).then((res) => {
            this.setState({
                trackingData: res.data.data[0],
                isLoading: false
            })
        }).catch((err) => { console.log(err) })
    }

    deleteTrackingItem = () => {
        Axios({
            method: 'DELETE',
            url: '/deletetracking',
            data: {
                id: this.state.deleteId
            }
        }).then((res) => {
            this.getTrackingAll()
            this.setState({deleteId:''})
            this.confirmDeleteToggle()
        }).catch((err) => {
            console.log(err)
        })
    }

    confirmDeleteToggle = () => { this.setState({ confirmDeleteIsOpen: !this.state.confirmDeleteIsOpen }) }

    setModalToggle = () => {
        this.setState({ modalIsOpen: !this.state.modalIsOpen })
    }

    render() {
        // const tableColumn = [
        //     {
        //         name: 'วันที่',
        //         selector: 'ship_date',
        //         sortable: false,
        //         format: row => dayjs(row.ship_date).format('DD/MM/YYYY')
        //     },
        //     {
        //         name: 'ชื่อผู้รับ',
        //         selector: 'receive_name',
        //         sortable: true
        //     },
        //     {
        //         name: 'Tracking No',
        //         selector: 'tracking_no',
        //         sortable: true
        //     },
        //     {
        //         name: 'ส่งโดย',
        //         selector: 'ship_by',
        //         sortable: true
        //     }
        // ]
        return (
            <Container>
                <Row style={{marginTop:10,MarginBottom:10}} >
                    <Button outline onClick={() => { this.setModalToggle() }}  >Add New Items</Button>
                </Row>
                <Row style={{marginTop:10,MarginBottom:10}} >
                    <InputGroup>
                        <Input placeholder="กรุณาใส่ชื่อใส่ช่องนี้" onChange={(e) => {
                            this.getTrackingAll(e.target.value)
                            this.setState({ keyword: e.target.value })
                        }} />
                        <InputGroupAddon addonType="append">
                            <Button onClick={this.getTrackingAll} style={{ width: 80 }}  >
                                <FaSearch />
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Row>
                <Row style={{marginTop:10}} >
                    <Table hover bordered striped responsive >
                        <thead>
                            <tr>
                                <th>ชื่อผู้รับ/วันที่</th>
                                <th>Tracking No.</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!this.state.isLoading ? this.state.trackingData.map((rows, index) => (
                                <tr key={index} >
                                    <td>
                                        <div>{rows.receive_name}</div>
                                        <div style={{ fontSize: 12, color: 'grey' }} >{dayjs(rows.ship_date).format('DD/MM/YYYY')}</div>
                                    </td>
                                    <td>
                                        <div>{rows.tracking_no}</div>
                                        <div style={{ fontSize: 12, color: 'grey' }} >{rows.ship_by}</div>
                                    </td>
                                    <td style={{ width: 25 }} >
                                        <Button outline onClick={() => {
                                            this.setState({ deleteId: rows.id })
                                            this.confirmDeleteToggle()
                                        }} ><FaTrash /></Button>
                                    </td>
                                </tr>)) : (
                                    <tr  >
                                        <td>
                                            <Spinner />
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </Table>
                </Row>
                <Row>
                    <Modal toggle={this.setModalToggle} isOpen={this.state.modalIsOpen} >
                        <ModalBody>
                            <Card>
                                <CardHeader>
                                    <h3>Add Tracking</h3>
                                </CardHeader>
                                <CardBody>
                                    <Input style={{ margin: 5 }} type='date' placeholder='Date' onChange={(e) => { this.setState({ shipDate: e.target.value }) }} />
                                    <Input style={{ margin: 5 }} type='text' placeholder='Name' onChange={(e) => { this.setState({ name: e.target.value }) }} />
                                    <Input style={{ margin: 5 }} type='select' placeholder='Send By' onChange={(e) => { this.setState({ shipBy: e.target.value }) }}>
                                        <option value={null} >เลือกวิธีการส่ง</option>
                                        <option value='Kerry Express' >Kerry Express</option>
                                        <option value='EMS' >EMS</option>
                                        <option value='Flash Express' >Flash Express</option>
                                        <option value='J&T Express' >J&T Express</option>
                                        <option value='Nim Express' >Nim Express</option>
                                        <option value='ขนส่ง' >ขนส่ง</option>
                                    </Input>
                                    <Input style={{ margin: 5 }} type='text' placeholder='Tracking No.' onChange={(e) => { this.setState({ trackingNo: e.target.value }) }} />
                                    <Input style={{ margin: 5 }} type='textarea' placeholder='Detail' onChange={(e) => { this.setState({ detail: e.target.value }) }} />
                                </CardBody>
                            </Card>
                        </ModalBody>
                        <ModalFooter>
                            <Button outline onClick={this.onAddTracking} >Save</Button>
                            <Button outline onClick={() => { this.setModalToggle() }}>Close</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.confirmDeleteIsOpen} toggle={this.confirmDeleteToggle} >
                        <ModalHeader>Delete This Items???</ModalHeader>
                        <ModalFooter>
                            <Button outline onClick={() => { this.deleteTrackingItem()}}>Yes</Button>
                            <Button outline onClick={()=>{this.confirmDeleteToggle()}}>No</Button>
                        </ModalFooter>
                    </Modal>
                </Row>
            </Container>
        )
    }
}