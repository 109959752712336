import React, { Component } from 'react'
import { Container, Card, CardHeader, CardBody, ModalFooter, CardFooter, Button, Row, Input, CardImg, Table, Modal, ModalBody, ModalHeader } from 'reactstrap'
import Axios from '../../Services/Axios'
import { imageUrl } from '../../Services/ImageUrl'
import dayjs from 'dayjs'
import { localToken } from '../../Services/Document'

export default class AddProductDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pddIsOpen: false,
            pdd_code: '',
            pdd_name: '',
            pdd_width: '',
            pdd_composition: '',
            pdd_detail: '',
            pdd_img: null,
            pdd_status: '',
            pdd_group: '',
            imageRender: '',
            productDetailGroupAllData: [],
            errorMessage: '',
            productGroupData: [],
            productTypeData: [],
            delPddIsOpen: false,
            delPddId: null
        }
    }

    componentDidMount = async () => {
        await this.getProductDetailGroupAll()
        await this.getProductGroup()
        await this.getProductType()
    }

    addProductDetailToogle = () => {
        this.setState((prevState) => ({
            pddIsOpen: !prevState.pddIsOpen
        }))
    }

    addProductDetail = async () => {
        if (this.state.pdd_code === ''
            || this.state.pdd_composition === ''
            || this.state.pdd_img === null
            || this.state.pdd_width === ''
            || this.state.pdd_status === ''
            || this.state.pdd_group === ''
        ) {
            this.setState({ errorMessage: 'กรุณากรอกข้อมูลให้ครบ' })
        } else {
            const form = new FormData()
            form.append('pdd_code', this.state.pdd_code)
            form.append('pdd_name', this.state.pdd_name)
            form.append('pdd_width', this.state.pdd_width)
            form.append('pdd_composition', this.state.pdd_composition)
            form.append('pdd_img', this.state.pdd_img)
            form.append('pdd_detail', this.state.pdd_detail)
            form.append('pdd_status', this.state.pdd_status)
            form.append('pdd_group', this.state.pdd_group)
            Axios.post('/pdd', form).then((res) => {
                this.setState({ errorMessage: 'Add Product Detail Successful ...' })
                this.getProductDetailGroupAll()
                setTimeout(() => { this.addProductDetailToogle() }, 500)
            }).catch((err) => {
                console.error(err)
            })
        }
    }

    getProductDetailGroupAll = async () => {
        await Axios.get('/getproductdetailgroupall').then((res) => {
            this.setState({ productDetailGroupAllData: res.data.data[0] })
        }).catch((err) => {
            console.error(err)
        })
    }

    getProductGroup = async () => {
        await Axios.get('/pdg').then((res) => {
            this.setState({ productGroupData: res.data.data })
        }).catch((err) => {
            console.error(err)
        })
    }

    getProductType = async () => {
        await Axios.get('/getproducttype',{
            headers: {
                Authorization: 'Bearer ' + localToken
            }
        }).then((res) => {
            this.setState({ productTypeData: res.data.data[0] })
        }).catch((err) => {
            console.error(err)
        })
    }

    selectFile = (e) => {
        let renderImage = new FileReader()
        let file = e.target.files[0]
        renderImage.onloadend = () => {
            this.setState({
                imageRender: renderImage.result,
                pdd_img: file,
                errorMessage: ''
            })
        }
        renderImage.readAsDataURL(file)
    }

    delPddToggle = () => {
        this.setState((prevState) => ({
            delPddIsOpen: !prevState.delPddIsOpen
        }))
    }

    delProductDetail = async () => {
        if (this.state.delPddId === null) {
            console.log('Plese Select Product')
        } else {
            Axios.delete('/pdd/' + this.state.delPddId).then((res) => {
                console.log(res)
                this.getProductDetailGroupAll()
                setTimeout(()=>{this.delPddToggle()},100)
            }).catch((err) => {
                console.error(err)
            })
        }
    }

    render() {
        // console.log(this.state)
        return (
            <Container>
                <Row>
                    <Modal isOpen={this.state.delPddIsOpen} toggle={this.delPddToggle} >
                        <ModalHeader>Delete Product Detail</ModalHeader>
                        <ModalBody>Are you sure !!!</ModalBody>
                        <ModalFooter>
                            <Button color='danger' onClick={() => { this.delProductDetail() }}>Delete</Button>
                            <Button onClick={this.delPddToggle}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.pddIsOpen} toggle={this.addProductDetailToogle} >
                        <ModalHeader>Add Product Detail</ModalHeader>
                        <ModalBody>
                            <Card>
                                <CardHeader>
                                    Add Product Detail
                        </CardHeader>
                                <CardBody>
                                    <Input type='select' placeholder='Select Group' onChange={(e) => { this.setState({ pdd_group: e.target.value }) }} >
                                        <option>Select Group</option>
                                        {this.state.productGroupData.map((rows, index) => (
                                            <option key={index} value={rows.pdg_id} >{rows.pdg_id + ' : ' + rows.pdg_name}</option>))}
                                    </Input>
                                    <Input type='text' placeholder='Product Code' onChange={(e) => { this.setState({ pdd_code: e.target.value }) }} />
                                    <Input type='text' placeholder='Product Name' onChange={(e) => { this.setState({ pdd_name: e.target.value }) }} />
                                    <Input type='text' placeholder='Width' onChange={(e) => { this.setState({ pdd_width: e.target.value }) }} />
                                    <Input type='select' placeholder='Composition' onChange={(e) => { this.setState({ pdd_composition: e.target.value }) }} >
                                        <option>Select Composition</option>
                                        {this.state.productTypeData.map((rows, index) => (
                                            <option key={index} value={rows.name} > {rows.id + ' : ' + rows.name} </option>))}
                                    </Input>
                                    <Input type='textarea' placeholder='Product Detail' onChange={(e) => { this.setState({ pdd_detail: e.target.value }) }} />
                                    <Input type='select' placeholder='Select Status' onChange={(e) => { this.setState({ pdd_status: e.target.value }) }} >
                                        <option>Select Status</option>
                                        <option>Actived</option>
                                        <option>Inactive</option>
                                    </Input>
                                    <Input type='file' placeholder='Choose Image' onChange={this.selectFile} />
                                    <CardImg src={this.state.imageRender} />
                                </CardBody>
                                <CardFooter>
                                    <h6 style={{ color: 'red' }} >{this.state.errorMessage}</h6>
                                </CardFooter>
                            </Card>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.addProductDetail} >Save</Button>
                            <Button onClick={this.addProductDetailToogle} >Cancel</Button> </ModalFooter>
                    </Modal>
                </Row>
                <Row>
                    <Button onClick={() => this.addProductDetailToogle()} ><i className='fa fa-plus' /> Add Product Detail</Button>
                </Row>
                <Row>
                    <Table striped >
                        <thead>
                            <tr>
                                {/* <th>Group Id</th>
                                <th>Group Img</th>
                                <th>Group Name</th> */}
                                <th>Detail Id</th>
                                <th>Group Id</th>
                                <th>Image</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Width</th>
                                <th>Composition</th>
                                <th>Detail</th>
                                <th>Status</th>
                                <th>Last Update</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.productDetailGroupAllData.map((rows, index) => (
                                <tr key={index} >
                                    {/* <td> {rows.pdg_id} </td>
                                    <td><img src={imageUrl.url + '/image/pdg/' + rows.pdg_image} alt='' style={{ height: 50, width: null }} /></td>
                                    <td> {rows.pdg_name} </td> */}
                                    <td>{rows.pdd_id}</td>
                                    <td> {rows.pdd_group} </td>
                                    <td><img src={imageUrl.url + '/image/pdd/' + rows.pdd_img} alt='' style={{ height: 50, width: null }} /></td>
                                    <td>{rows.pdd_code}</td>
                                    <td> {rows.pdd_name} </td>
                                    <td>{rows.pdd_width}</td>
                                    <td>{rows.pdd_composition}</td>
                                    <td>{rows.pdd_detail}</td>
                                    <td>{rows.pdd_status === 0 ? (<p>Inactive</p>) : (<p>Actived</p>)}</td>
                                    <td> {dayjs(rows.pdd_create_date).format('DD/MM/YYYY')} </td>
                                    <td onClick={() => {
                                        this.setState({ delPddId: rows.pdd_id })
                                        this.delPddToggle()
                                    }} >
                                        <i className='fa fa-trash' style={{ fontSize: 25, color: 'red' }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        )
    }
}
