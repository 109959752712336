import { combineReducers } from 'redux'
import LoginReducers from './Users/LoginReducers'
import LabReducers from './Products/LabReducers'
import ProductReducers from './Products/ProductReducers'

const rootReducers = combineReducers({
    Users: LoginReducers,
    LabData:LabReducers,
    Product:ProductReducers
})

export default rootReducers