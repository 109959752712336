import React, { Component } from 'react'
import { Table, Input, Button, Row, Breadcrumb, BreadcrumbItem, Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import axios from '../../../Services/Axios'
import { localToken as token } from '../../../Services/Document'
import { serverApiKey as APIKEY } from '../../../Services/Document'

class WarehouseReportByRoll extends Component {
    constructor(props) {
        super(props)
        this.state = {
            wareHouse: '',
            startDate: '',
            endDate: '',
            minQty: '',
            WareHouseReportByRollData: [],
            totalRoll: ''
        }
    }

    getWarehouseReportByRoll = async () => {
        await axios({
            method: 'POST',
            url: '/warehouserptbyroll',
            data: {
                TOKEN: APIKEY.WAREHOUSERPTBYORDER,
                P_WARE_HOUSE: this.state.wareHouse,
                P_INV_START_DATE: this.state.startDate,
                P_INV_END_DATE: this.state.endDate,
                P_MIN_QTY: this.state.minQty,
            },
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            this.setState({
                WareHouseReportByRollData: res.data.result
            })
            this.getSum()
        }).catch((err) => {
            console.log(err)
        })
    }
    getSum = () => {
        var total = 0
        for (var i = 0; i < this.state.WareHouseReportByRollData.length; i++) {
            total += Number(this.state.WareHouseReportByRollData[i].TOTAL_ROLL)
        }
        this.setState({ totalRoll: total })
    }

    render() {
        // console.log(this.state)
        return (
            <Container>
                <Row>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to='/'>Back Office Home</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to='/report'>Report</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to='/getwarehousereportbyroll'>Report By Roll</Link></BreadcrumbItem>
                    </Breadcrumb>
                </Row>
                <Table dark>
                    <thead>
                        <tr>
                            <th>Warehouse</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Min Yard</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Input placeholder='Ware House' type='select' onChange={(e) => this.setState({ wareHouse: e.target.value })} >
                                    <option>Select Warehouse</option>
                                    <option value='01'>Office</option>
                                    <option value='02'>Sai 5</option>
                                </Input>
                            </td>
                            <td>
                                <Input placeholder='Start Date' type='date' onChange={(e) => this.setState({ startDate: e.target.value })} />
                            </td>
                            <td>
                                <Input placeholder='End Date' type='date' onChange={(e) => this.setState({ endDate: e.target.value })} />
                            </td>
                            <td>
                                <Input placeholder='Min Yards' type='number' onChange={(e) => this.setState({ minQty: e.target.value })} />
                            </td>
                            <td>
                                <Button onClick={this.getWarehouseReportByRoll} >Search</Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <Table dark>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Number Rolls</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.WareHouseReportByRollData.map((rows, index) => (
                            <tr key={index} >
                                <th scope="row">{index + 1}</th>
                                <td>{rows.INV_DATE}</td>
                                <td>{rows.TOTAL_ROLL}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th style={{ fontWeight: 'bold', fontSize: 20 }} >TOTAL ROLLS : </th>
                            <th style={{ fontWeight: 'bold', fontSize: 20 }} >{this.state.totalRoll}</th>
                        </tr>
                    </tfoot>
                </Table>
            </Container>
        )
    }
}
export default WarehouseReportByRoll