import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'

export default class Charts extends Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <Link to='/sellqtybymonthbysales' >
                            <i className='fa fa-random' style={{fontSize:120}} />
                            <h5>SELL QTY BY MONTH BY SALES</h5>
                        </Link>
                    </Col>
                    <Col>
                        <Link to='/sellamountsalescomparebyear' >
                            <i className='fa fa-random' style={{fontSize:120}} />
                            <h5>SELL AMOUNT SALES COMPARE BY YEAR</h5>
                        </Link>
                    </Col>
                </Row>
            </Container>
        )
    }
}
