// Users
export const SET_LOGIN_USER_ID = 'SET_LOGIN_USER_ID'
export const SET_LOGIN_USER_NAME = 'SET_LOGIN_USER_NAME'
export const SET_LOGIN_USER_MOBILE = 'SET_LOGIN_USER_MOBILE'
export const SET_LOGIN_USER_EMAIL = 'SET_LOGIN_USER_EMAIL'
export const SET_LOGIN_USER_IS_LOGIN = 'SET_LOGIN_USER_IS_LOGIN'

//Lab
export const SET_LAB_DATA = 'SET_LAB_DATA'
export const SET_LAB_LOADING = 'SET_LAB_LOADING'

//Product
//Product Item
export const GET_PRODUCT_ITEM_DATA_ALL = 'GET_PRODUCT_ITEM_DATA_ALL'

//Product Detail
export const GET_PRODUCT_DETAIL_DATA_ALL = 'GET_PRODUCT_DETAIL_DATA_ALL'