import React, { Component } from 'react'
import { Container, Col, Row, Card, CardHeader, CardBody } from 'reactstrap'
import 'font-awesome/css/font-awesome.css'
import { Link } from 'react-router-dom'

export default class Admin extends Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col style={styles.Col} >
                        <Link to='/addproductgroup' >
                            <Card>
                                <CardHeader>Add Product Group</CardHeader>
                                <CardBody>
                                    <i className='fa fa-group' style={styles.Icon} />
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                    <Col style={styles.Col} >
                        <Link to='/addproductdetail' >
                            <Card>
                                <CardHeader>Add Product Detail</CardHeader>
                                <CardBody>
                                    <i className='fa fa-list' style={styles.Icon} />
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                    <Col style={styles.Col} >
                        <Link to='/addproductitem' >
                            <Card>
                                <CardHeader>Add Product Items</CardHeader>
                                <CardBody>
                                    <i className='fa fa-signal' style={styles.Icon} />
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                    <Col style={styles.Col} >
                        <Link to='/addtracking' >
                            <Card>
                                <CardHeader>Add Product Items</CardHeader>
                                <CardBody>
                                    <i className='fa fa-truck' style={styles.Icon} />
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                </Row>
            </Container>
        )
    }
}


const styles = {
    Icon: {
        fontSize: 220
    },
    Col: {
        flex: 1,
        justifyContent: 'center',
        alignSelf: 'center'
    }
}