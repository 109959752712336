import React, { Component } from 'react'
import { Container,Row,Col, Breadcrumb, BreadcrumbItem  } from 'reactstrap'
import {Link} from 'react-router-dom'
import 'font-awesome/css/font-awesome.css'

export default class Report extends Component {
    render() {
        return (
             <Container>
             <Row>
                 <Breadcrumb>
                     <BreadcrumbItem><Link to='/'>Back Office Home</Link></BreadcrumbItem>
                     <BreadcrumbItem><Link to='/report'>Report</Link></BreadcrumbItem>
                 </Breadcrumb>
             </Row>
            <Row style={{ flex: 1, justifyContent: 'center' }} >
                <h1>Wellcome {this.props.userFullName}</h1>
            </Row>
            <Row style={style.Row}>
                <Link to='/getwarehousereportbyroll' >
                    <Col style={style.Col} >
                        <i className='fa fa-minus' style={{ fontSize: 80 }} />
                        <h5 style={{ margin: 10 }} >Ware House By Roll</h5>
                    </Col>
                </Link>
                <Link to='/getwarehousereportbyorders'>
                    <Col style={style.Col} >
                        <i className='fa fa-sticky-note' style={{ fontSize: 80 }} />
                        <h5 style={{ margin: 10 }} >Ware House By Orders</h5>
                    </Col>
                </Link>
                <Link to='/sell' >
                    <Col style={style.Col}>
                        <i className='fa fa-address-book' style={{ fontSize: 80 }} />
                        <h5 style={{ margin: 10 }} >Sell</h5>
                    </Col>
                </Link>
            </Row>
        </Container>
        )
    }
}

const style = {
    Col: {
        flex: 1,
        justifyContent: 'center',
        padding: 30
    },
    Row: {
        flex: 1,
        justifyContent: 'space-around',

    }
}