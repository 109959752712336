import React, { Component } from 'react'
import { Chart } from 'react-google-charts'
import Axios from '../../Services/Axios'
import { localToken } from '../../Services/Document'
import { Row, Container, Spinner, Table } from 'reactstrap'

export default class SellAmountSalesCompareByYear extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allData: [],
            chartData: null,
            loading: true
        }
    }
    componentDidMount = async () => {
        await this.getSellAmountSalesCompareByYear()
    }

    getSellAmountSalesCompareByYear = async () => {
        await Axios({
            method: "POST",
            url: '/sellamountsalescomparebyyear',
            data: {
                year: 2019
            },
            headers: {
                Authorization: 'Bearer ' + localToken
            }
        }).then((res) => {
            // var data = res.data.data[0].map((res) => {
            //     return [
            //         // res.SALES_NAME,
            //          res.SUM_INV_TOTAL_BF_VAT, 
            //          res.SUM_INV_GRAND_TOTAL]
            // })
            // console.log('Data:', data)
            var chartData =[['SALES','BF','AF']]
            var response = res.data.data[0]
            // chartData.push(data)
            for (let i = 0; i < response.length; i += 1) {
                chartData.push([response[i].SALES_NAME,response[i].SUM_INV_TOTAL_BF_VAT,response[i].SUM_INV_GRAND_TOTAL])
            }
            this.setState({ allData: response, chartData: chartData, loading: false })

        }).catch((err) => {
            console.error(err)
        })
    }

    numberFormat = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    render() {
        console.log('chart Data:', this.state.chartData)
        return (
            <Container>
                <Row>
                     {this.state.loading ? (<Spinner size='large' />) : (
                        <Chart
                            chartType="Bar"
                            width="100%"
                            height="400px"
                            data={this.state.chartData}
                            legendToggle
                            options={{
                                animation: {
                                    startup: true,
                                    easing: 'linear',
                                    duration: 1500,
                                },
                            }}
                            chartEvents={[
                                {
                                    eventName: 'animationfinish',
                                    callback: () => {
                                        console.log('Animation Finished')
                                    },
                                },
                            ]}
                        />)}
                </Row>
                <Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>YEAR</th>
                                <th>SALES CODE</th>
                                <th>SALES NAME</th>
                                <th>TOTAL BF VAT</th>
                                <th>VAT AMT</th>
                                <th>TOTAL AF VAT</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.allData.map((rows, index) => (
                                <tr key={index} >
                                    <td>{rows.INV_YEAR}</td>
                                    <td>{rows.SALES_CODE}</td>
                                    <td>{rows.SALES_NAME}</td>
                                    <td>{this.numberFormat(rows.SUM_INV_TOTAL_BF_VAT.toFixed(2))}</td>
                                    <td>{this.numberFormat(rows.SUM_VAT_AMT.toFixed(2))}</td>
                                    <td>{this.numberFormat(rows.SUM_INV_GRAND_TOTAL.toFixed(2))}</td>
                                </tr>))}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        )
    }
}
