import React, { useState, useEffect } from 'react'
import { ModalHeader, ModalBody, ModalFooter, Button, Input, Spinner } from 'reactstrap'
import Axios from '../../Services/Axios'
import { localToken as token } from '../../Services/Document'

const AdPriceListComponents = (props) => {
    const [colorShade, setColorShade] = useState(null)
    const [minPrice, setMinPrice] = useState(null)
    const [salesPrice, setSalesPrice] = useState(null)
    const [status, setStatus] = useState(null)
    const [conditions, setConditions] = useState(null)
    const [addPriceListLoading, setAddPriceListLoading] = useState(false)
    const [colorShadeData, setColorShadeData] = useState([])
    const [productStatus] = useState([{ lable: 'Running', value: 'Running' }, { lable: 'Not Running', value: 'Not Running' }, { lable: 'Pre-Order', value: 'Pre-Order' }, { lable: 'Stock', value: 'Stock' }])

    useEffect(() => {
        getProductColorShade()
    }, [])

    const getProductColorShade = async () => {
        Axios({
            method: 'GET', url: '/getproductcolorshade',
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res)
            setColorShadeData(res.data.data[0])
        }).catch((err) => {
            console.log(err)
        })
    }


    const addPriceList = async () => {
        if (minPrice === null ||
            salesPrice === null ||
            status === null ||
            colorShade === null) {
            alert('Please Check Your Data !!!')
        } else {
            setAddPriceListLoading(true)
            const now = new Date()
            await Axios({
                method: 'POST',
                url: '/addpricelist',
                data: {
                    create_date: now,
                    code: props.selectedCode,
                    color_shade: colorShade,
                    sale_price: salesPrice,
                    min_price: minPrice,
                    update_date: now,
                    status: status,
                    conditions: conditions
                },
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then((res) => {
                // console.log(res)
                setAddPriceListLoading(false)
                props.setAddPricelistIsOpen(false)
                setTimeout(() => {
                    props.getPriceListData(props.selectedCode)
                }, 500)
            }).catch((err) => {
                console.log(err)
                setAddPriceListLoading(false)
            })
        }
    }
    return (
        <div>
            <ModalHeader>Add Price List</ModalHeader>
            <ModalBody>
                <p>Selected : {props.selectedCode}</p>
                <Input type='select' placeholder='Color Shade' onChange={(e) => { setColorShade(e.target.value) }} >
                    <option value={null}>Select Color Shade</option>
                    {colorShadeData.map((rows, index) => (
                        <option key={index} value={rows.name} >{rows.name}</option>
                    ))}
                </Input>
                <Input type='float' placeholder='Min Price' onChange={(e) => { setMinPrice(e.target.value) }} />
                <Input type='float' placeholder='Sales Price' onChange={(e) => { setSalesPrice(e.target.value) }} />
                <Input type='select' placeholder='Select Your Status' onChange={(e) => { setStatus(e.target.value) }} >
                    <option value={null} >Please Select Status</option>
                    {productStatus.map((rows, i) =>

                        <option key={i} value={rows.value} >{rows.lable}</option>
                    )}
                </Input>
                <Input type='textarea' placeholder='Conditions' onChange={(e) => { setConditions(e.target.value) }} />
            </ModalBody>
            <ModalFooter>
                {addPriceListLoading ? (<Spinner />) : (
                    <Button outline onClick={() => { addPriceList() }} >Save</Button>
                )}
                <Button outline onClick={() => { props.setAddPricelistIsOpen(false) }} >Cancel</Button>
            </ModalFooter>
        </div>
    )
}

export default AdPriceListComponents
