import React, { useState, useEffect } from 'react'
import { localToken as token } from '../../Services/Document'
import Axios from '../../Services/Axios'
import { ModalHeader, ModalBody, ModalFooter, Input, Button, Spinner } from 'reactstrap'

const AddProductListComponents = (props) => {
    const [productTypeLoading, setProductTypeLoading] = useState(false)
    const [productTypeData, setProductTypeData] = useState([])
    const [code, setCode] = useState(null)
    const [name, setName] = useState(null)
    const [composition, setComposition] = useState(null)
    const [density, setDensity] = useState(null)
    const [construction, setConstruction] = useState(null)
    const [width, setWidth] = useState(null)
    const [weaving, setWeaving] = useState(null)
    const [finishing, setFinishing] = useState(null)
    const [weight, setWeight] = useState(null)
    const [details, setDetails] = useState(null)

    useEffect(() => {
        getProductTypeData()
    }, [])

    const getProductTypeData = async () => {
        setProductTypeLoading(true)
        Axios({
            method: 'GET',
            url: '/getproducttype',
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res.data.data[0])
            setProductTypeData(res.data.data[0])
            setProductTypeLoading(false)
        }).catch((err) => {
            console.log(err)
            setProductTypeLoading(false)
        })
    }

    const addProductList = async () => {
        await Axios({
            url: '/addproductslist',
            method: 'POST',
            data: {
                type: null,
                code: code,
                name: name,
                composition: composition,
                construction: construction,
                density: density,
                width: width,
                weaving: weaving,
                finishing: finishing,
                weight: weight,
                details: details
            },
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res)
            props.setAddProductListIsOpen(false)
            props.getPriceListData()
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <div>
            <ModalHeader>Add Product List</ModalHeader>
            <ModalBody>
                <Input type='text' placeholder='Product Code' onChange={(e) => { setCode(e.target.value) }} />
                <Input type='text' placeholder='Product Name' onChange={(e) => { setName(e.target.value) }} />
                {productTypeLoading ? (<Spinner />) : (
                    <Input type='select' onChange={(e) => { setComposition(e.target.value) }} >
                        <option value=''>Select Composition</option>
                        {productTypeData.map((rows, index) => (
                            <option key={index} value={rows.name}>{rows.id + ' : ' + rows.name}</option>))}
                    </Input>)}
                <Input type='text' placeholder='Construction' onChange={(e) => { setConstruction(e.target.value) }} />
                <Input type='text' placeholder='Density' onChange={(e) => { setDensity(e.target.value) }} />
                <Input type='text' placeholder='Width' onChange={(e) => { setWidth(e.target.value) }} />
                <Input type='text' placeholder='Weaving' onChange={(e) => { setWeaving(e.target.value) }} />
                <Input type='select' placeholder='Finishing' onChange={(e) => { setFinishing(e.target.value) }} >
                    <option>Select Finishing</option>
                    <option value='NORMAL' >NORMAL</option>
                    <option value='LAFER'>LAFER</option>
                </Input>
                <Input type='text' placeholder='Weight' onChange={(e) => { setWeight(e.target.value) }} />
                <Input type='textarea' placeholder='Product Detail' onChange={(e) => { setDetails(e.target.value) }} />
            </ModalBody>
            <ModalFooter>
                <Button outline onClick={()=>{addProductList()}} >Save</Button>
                <Button outline onClick={() => { props.setAddProductListIsOpen(false) }} >Cancel</Button>
            </ModalFooter>
        </div>
    )
}

export default AddProductListComponents
