import React, { Component } from 'react'
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap'
import { Link } from 'react-router-dom'

import axios from '../../Services/Axios'

import { connect } from 'react-redux'
import { setUserEmail, setUserId, setUserIsLogin, setUserMobile, setUserName } from '../../Redux/Actions/Users/LoginActions'

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            message: ''
        }
    }

    onLogin = async () => {
        if (this.state.email === '' || this.state.password === '') {
            this.setState({ message: 'User Or Password Cannot Be Null' })
        } else {
            axios({
                method: 'POST',
                url: '/signin',
                data: {
                    email: this.state.email,
                    password: this.state.password
                }
            }).then((res) => {
                // console.log(res.data)
                this.setState({ message: 'Login Successful ...' })
                localStorage.setItem('KPMTEXTILE_BACKOFFICE_TOKEN', res.data.token)
                this.props.setUserEmail(res.data.data.email)
                this.props.setUserId(res.data.data.user_id)
                this.props.setUserName(res.data.data.first_name + ' ' + res.data.data.last_name)
                this.props.setUserIsLogin(true)
                this.props.setUserMobile(res.data.data.mobile)
                this.props.history.push('/')
            }).catch((err) => {
                console.error(err)
                this.setState({ message: 'Loggin Faild !!!' })
            })
        }
    }

    render() {
        // console.log('Login:',this.props)
        return (
            <Container>
                <Row className="justify-content-center">
                    <Col md="8">
                        <CardGroup>
                            <Card className="p-4">
                                <CardBody>
                                    <h1>Login</h1>
                                    <p className="text-muted">Sign In to your account</p>
                                    <p style={{ color: 'red' }} >{this.state.message}</p>
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="icon-email"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="text" id="email" name="email" placeholder="Email Or Mobile Number" onChange={(e) => { this.setState({ email: e.target.value }) }} />
                                    </InputGroup>
                                    <InputGroup className="mb-4">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="icon-lock"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="password" name="password" id="password" placeholder="Password" onChange={(e) => { this.setState({ password: e.target.value }) }} />
                                    </InputGroup>
                                    <Row>
                                        <Col xs="6">
                                            <Button color="primary" className="px-4" onClick={this.onLogin} >Login</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6" className="text-right">
                                            <Button color="link" className="px-0">
                                                <Link to='forgotpassword'> Forgot password?</Link>
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 50 + '%' }}>
                                <CardBody className="text-center">
                                    <div>
                                        <h2>Sign up</h2>
                                        <p>Register your account to login</p>
                                        <Button color="primary" className="mt-3" href="/register" active>Register Now!</Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => ({
    Users: state.Users
})

const mapDispatchToProps = (dispatch) => ({
    setUserEmail: (email) => {
        dispatch(setUserEmail(email))
    },
    setUserId: (id) => {
        dispatch(setUserId(id))
    },
    setUserIsLogin: (islogin) => {
        dispatch(setUserIsLogin(islogin))
    },
    setUserMobile: (mobile) => {
        dispatch(setUserMobile(mobile))
    },
    setUserName: (name) => {
        dispatch(setUserName(name))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)