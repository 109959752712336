import React from 'react'
import { 
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    // NavItem,
    // NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import { setUserIsLogin } from '../../Redux/Actions/Users/LoginActions'

class Navigation extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        }
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    logOut = async () => {
        localStorage.removeItem('KPMTEXTILE_BACKOFFICE_TOKEN')
        this.props.setUserIsLogin(false)
        this.props.history.push('/login')
    }
    render() {
        // console.log({ NavBar: this.props })
        return (
            <div>
                <Navbar color="light" light expand="md">
                    <NavbarBrand style={{cursor:'pointer'}} onClick={()=>{this.props.history.push('/')}}>Console</NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            {/*<NavItem>
                                <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
                            </NavItem> */}
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>Profile</DropdownToggle>
                                <DropdownMenu right>
                                    {!this.props.Users.userIsLogin ? (<div>
                                        <DropdownItem><Link to='/login' >Login</Link></DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem><Link to='/register'>Register </Link></DropdownItem>
                                    </div>
                                    ) : (
                                            <div>
                                            <DropdownItem><Link to='/profile' >Profile</Link></DropdownItem>
                                                <DropdownItem divider />
                                                <DropdownItem onClick={this.logOut} >Log Out</DropdownItem>
                                            </div>
                                        )}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                             {/* <NavItem >
                                <p style={{color:'grey',fontSize:10}}>V.{versionCode}</p>
                            </NavItem> */}
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    Users: state.Users
})

const mapDispatchToProps = (dispatch) => ({
    setUserIsLogin: (islogin) => {
        dispatch(setUserIsLogin(islogin))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)