import React, { useState, useEffect } from 'react'
import { ModalBody, ModalFooter, ModalHeader, Button, Input } from 'reactstrap'
import Axios from '../../Services/Axios'
import { localToken as token } from '../../Services/Document'

const AddCostListComponents = (props) => {
    const [colorShade, setColorShade] = useState(null)
    const [costBfVat, setCostBfVat] = useState(null)
    const [colorShadeData, setColorShadeData] = useState([])

    useEffect(() => {
        getProductColorShade()
    }, [])

    const getProductColorShade = async () => {
        Axios({
            method: 'GET', url: '/getproductcolorshade',
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res)
            setColorShadeData(res.data.data[0])
        }).catch((err) => {
            console.log(err)
        })
    }

    const addCostListData = async () => {
        const timeStamp = new Date()
        if (costBfVat === null || colorShade === null) {
            alert('Please Check Yout Data !!!')
        } else {
            await Axios({
                url: '/addproductpricedetail',
                method: 'POST',
                data: {
                    product_article: props.selectedCode,
                    color_shade: colorShade,
                    cost_bf_vat: costBfVat,
                    update_date: timeStamp
                },
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then((res) => {
                // console.log(res)
                props.setAddCostListIsOpen(false)
                props.getCostListData(props.selectedCode)
            }).catch((err) => {
                console.log(err)
            })
        }
    }
    return (
        <div>
            <ModalHeader>Add New Cost</ModalHeader>
            <ModalBody>
                <p>{props.selectedCode}</p>
                <Input type='select' placeholder='Color Shade' onChange={(e) => { setColorShade(e.target.value) }} >
                    <option value={null} >Select Color Shade</option>
                    {colorShadeData.map((rows, index) => (
                        <option key={index} value={rows.name} >{rows.name}</option>
                    ))}
                </Input>
                <Input type='text' placeholder='Cost Before Vat' onChange={(e) => { setCostBfVat(e.target.value) }} />
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => {  addCostListData() }} >Save</Button>
                <Button onClick={() => { props.setAddCostListIsOpen(false) }} >Cancel</Button>
            </ModalFooter>
        </div>
    )
}

export default AddCostListComponents
