import {SET_LOGIN_USER_EMAIL,SET_LOGIN_USER_ID,SET_LOGIN_USER_IS_LOGIN,SET_LOGIN_USER_MOBILE,SET_LOGIN_USER_NAME } from '../../Type'

export const setUserId = (id)=>({
    type:SET_LOGIN_USER_ID,
    setId:id
})
export const setUserName = (name)=>({
    type:SET_LOGIN_USER_NAME,
    setName:name
})
export const setUserEmail = (email)=>({
    type:SET_LOGIN_USER_EMAIL,
    setEmail:email
})
export const setUserMobile = (mobile)=>({
    type:SET_LOGIN_USER_MOBILE,
    setMobile:mobile
})
export const setUserIsLogin = (islogin)=>({
    type:SET_LOGIN_USER_IS_LOGIN,
    setIsLogin:islogin
})