import React, { Component } from 'react'
import { Table } from 'reactstrap'
import axios from '../../Services/Axios'
import { localToken } from '../../Services/Document'
import { Spinner, Modal, ModalHeader, ModalFooter, Button, Input, InputGroup, InputGroupAddon, ModalBody, Row, Col } from 'reactstrap'
import { FaTrash, FaPencilAlt } from 'react-icons/fa'

import { connect } from 'react-redux'
import { setLabData, setLabLoading } from '../../Redux/Actions/Products/LabActions'

class Lab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            defaultStatus: '%',
            deleteOpen: false,
            deleteId: '',
            sales: [],
            modal: false,
            id: '',
            supplier_name: '',
            pick_date: '',
            sales_code: '',
            sales_name: '',
            products: '',
            details: '',
            return_date: '',
            labStatus: '',// Waiting , Picked , Returned  
            customers: '',
            editModalIsOpen: false,
            updateId: '',
            updateSupplierName: '',
            updatePickDate: '',
            updateSaleName: '',
            updateProducts: '',
            updateDetails: '',
            updateReturnDate: '',
            updateLabStatus: '',
            updateCustomers: '',
            updateSaleCode: ''
        }
    }

    componentDidMount = () => {
        this.getLabData()
        this.getSalesAll()
    }

    getLabData = async () => {
        this.props.setLabLoading(true)
        axios({
            url: '/getlab',
            method: 'post',
            data: {
                status: this.state.defaultStatus
            },
            headers: {
                Authorization: 'Bearer ' + localToken
            }
        }).then((res) => {
            this.props.setLabData(res.data.data[0])
            this.props.setLabLoading(true)
        }).catch((err) => {
            console.log(err)
        })
    }

    getSalesAll = async () => {
        axios({
            url: '/getsalesall',
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localToken
            }
        }).then((res) => {
            // console.log(res.data.data[0])
            this.setState({
                sales: res.data.data[0]
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    deleteLabData = async () => {
        axios({
            url: '/deletelab',
            method: 'DELETE',
            data: {
                id: this.state.deleteId
            },
            headers: {
                Authorization: 'Bearer ' + localToken
            }
        }).then((res) => {
            this.setState({ message: '1 Rows Deleted' })
            setTimeout(() => { this.getLabData() }, 200)
            this.setState({ deleteOpen: false })
        }).catch((err) => {
            console.log(err)
        })
    }

    toggle = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal
        }))
    }

    handleChangeLabItem = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    saveItem = async () => {
        if (this.state.customers === '' || this.state.sales_name === '') {
            alert('กรุณากรอกข้อมูล')
        } else {
            axios({
                url: '/addlab',
                method: 'POST',
                data: {
                    id: this.state.id,
                    supplierName: this.state.supplier_name,
                    pickDate: this.state.pick_date,
                    salesCode: this.state.sales_code,
                    salesName: this.state.sales_name,
                    products: this.state.products,
                    details: this.state.details,
                    returnDate: this.state.return_date,
                    status: this.state.labStatus, /* Waiting , Picked , Returned  */
                    customers: this.state.customers
                },
                headers: {
                    Authorization: 'Bearer ' + localToken
                }
            }).then((res) => {
                this.setState({
                    message: 'Add Item Completed ... '
                })
                setTimeout(() => { this.toggle() }, 100)
                setTimeout(() => { this.getLabData() }, 200)
                this.setState({
                    id: '',
                    supplier_name: '',
                    pick_date: '',
                    sales_code: '',
                    sales_name: '',
                    products: '',
                    details: '',
                    return_date: '',
                    status: '', /* Waiting , Picked , Returned  */
                    customers: ''
                })
            }).catch((err) => {
                this.setState({ message: 'Save Item Error ...' })
            })
        }
    }

    editToggle = () => {
        this.setState((prevState) => ({
            editModalIsOpen: !prevState.editModalIsOpen
        }))
    }
    editLabData = async () => {
        axios({
            url: '/editlab',
            method: 'PATCH',
            data: {
                id: this.state.updateId,
                supplierName: this.state.updateSupplierName,
                pickDate: this.state.updatePickDate,
                salesCode: this.state.updateSaleCode,
                salesName: this.state.updateSaleName,
                products: this.state.updateProducts,
                details: this.state.updateDetails,
                returnDate: this.state.updateReturnDate,
                status: this.state.updateLabStatus, /* Waiting , Picked , Returned  */
                customers: this.state.updateCustomers
            },
            headers: {
                Authorization: 'Bearer ' + localToken
            }
        }).then((res) => {
            this.setState({
                message: 'Add Item Completed ... '
            })
            setTimeout(() => { this.editToggle() }, 100)
            setTimeout(() => { this.getLabData() }, 200)
        }).catch((err) => {
            this.setState({ message: 'Save Item Error ...' })
        })
    }

    render() {
        // console.log(this.state)
        return (
            <div>
                <Row>
                    <Col>
                        <Button onClick={()=>{this.setState({defaultStatus:'Waiting'})}} >Waiting</Button>
                    </Col>
                    <Col>
                        <Button onClick={()=>{this.setState({defaultStatus:'Picked'})}} >Picked</Button>
                    </Col>
                    <Col>
                        <Button onClick={()=>{this.setState({defaultStatus:'Returned'})}} >Returned</Button>
                    </Col>
                    <Col>
                        <Button style={{backgroundColor:'red'}} onClick={() => { this.setState({ modal: true }) }} ><i className='fa fa-plus' /></Button>
                    </Col>
                </Row>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>เพิ่มรายการ</ModalHeader>
                    <ModalBody>
                        <InputGroup>
                            <InputGroupAddon addonType='prepend' >วันที่</InputGroupAddon>
                            <Input type='date' placeholder='วันที่' name='pick_date' onChange={this.handleChangeLabItem} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType='prepend'>โรงงาน</InputGroupAddon>
                            <Input type='text' placeholder='โรงงาน' name='supplier_name' onChange={this.handleChangeLabItem} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType='prepend'>เซลส์</InputGroupAddon>
                            <Input type='select' placeholder='เซลส์' onChange={(e) => {
                                this.setState({
                                    sales_name: e.target.value
                                })
                            }} >
                                <option>Please Select</option>
                                {this.state.sales.map((rows, index) => (
                                    <option key={index} value={rows.sales_name} >{rows.sales_name}</option>
                                ))}
                            </Input>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType='prepend'>รหัสสินค้า</InputGroupAddon>
                            <Input type='text' placeholder='รหัสสินค้า' name='products' onChange={this.handleChangeLabItem} />
                        </InputGroup>
                        <InputGroup>
                            <Input type='textarea' placeholder='รายละเอียด' name='details' onChange={this.handleChangeLabItem} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType='prepend'>วันที่ประมาณการได้รับ</InputGroupAddon>
                            <Input type='date' placeholder='วันที่ประมาณการได้รับ' name='return_date' onChange={this.handleChangeLabItem} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType='prepend'>สถานะ</InputGroupAddon>
                            <Input type='select' placeholder='สถานะ' name='labStatus' onChange={this.handleChangeLabItem} >
                                <option>Please Select</option>
                                <option value='Waiting'>รอส่งโรงงาน</option>
                                <option value='Picked'>ส่งโรงงานแล้ว</option>
                                <option value='Returned'>ได้รับคืนจากโรงงานแล้ว</option>
                            </Input>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType='prepend'>ลูกค้า</InputGroupAddon>
                            <Input type='text' placeholder='ลูกค้า' name='customers' onChange={this.handleChangeLabItem} />
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.saveItem}>Save</Button>
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Table dark>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>วันที่</th>
                            <th>โรงงาน</th>
                            <th>เซลส์</th>
                            <th>รหัสผ้า</th>
                            <th>รายละเอียด</th>
                            <th>วันที่นัดส่ง</th>
                            <th>ชื่อลูกค้า</th>
                            <th>สถานะ</th>
                            <th>แก้ไข</th>
                            <th>ลบ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!this.props.LabData.loaing ? this.props.LabData.labData.map((items, index) => (
                            <tr key={index} >
                                <td>{items.id}</td>
                                <td>{items.pick_date}</td>
                                <td>{items.supplier_name}</td>
                                <td>{items.sales_name}</td>
                                <td>{items.products}</td>
                                <td>{items.details}</td>
                                <td>{items.return_date}</td>
                                <td>{items.customers}</td>
                                <td>{items.status}</td>
                                <td><FaPencilAlt onClick={() => {
                                    this.setState({
                                        editModalIsOpen: true,
                                        updateId: items.id,
                                        updateSupplierName: items.supplier_name,
                                        updatePickDate: items.pick_date,
                                        updateSaleName: items.sales_name,
                                        updateProducts: items.products,
                                        updateDetails: items.details,
                                        updateReturnDate: items.return_date,
                                        updateLabStatus: items.status,
                                        updateCustomers: items.customers
                                    })
                                }} /></td>
                                <td><FaTrash onClick={() => { this.setState({ deleteOpen: true, deleteId: items.id }) }} /></td>
                            </tr>
                        )) : (
                                <div style={{ justifyContent: 'center', alignContent: 'center' }} >
                                    <Spinner style={{ alignSelf: 'center' }} />
                                </div>
                            )}
                    </tbody>
                </Table>

                <Modal isOpen={this.state.deleteOpen} >
                    <ModalHeader>
                        <h3 style={{ color: 'red' }} >คุณแน่ใจที่จะลบรายการนี้</h3>
                    </ModalHeader>
                    <ModalBody>
                        <h5>ลบแล้วไม่สามารถนำกลับมาได้</h5>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='danger' onClick={this.deleteLabData} >Delete</Button>
                        <Button onClick={() => { this.setState({ deleteOpen: false }) }} >Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.editModalIsOpen} toggle={this.editToggle} className={this.props.className}>
                    <ModalHeader toggle={this.editToggle}>แก้ไขรายการ</ModalHeader>
                    <ModalBody>
                        <InputGroup>
                            <InputGroupAddon addonType='prepend'>วันที่</InputGroupAddon>
                            <Input type='date' placeholder='วันที่' name='pick_date' defaultValue={this.state.updatePickDate} onChange={(e) => { this.setState({ updatePickDate: e.target.value }) }} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType='prepend'>โรงงาน</InputGroupAddon>
                            <Input type='text' placeholder='โรงงาน' name='supplier_name' defaultValue={this.state.updateSupplierName}
                                onChange={(e) => { this.setState({ updateSupplierName: e.target.value }) }} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType='prepend'>เซลส์</InputGroupAddon>
                            <Input type='select' placeholder='เซลส์' defaultValue={this.state.updateSaleName}
                                onChange={(e) => { this.setState({ updateSaleName: e.target.value }) }} >
                                <option>Please Select</option>
                                {this.state.sales.map((rows, index) => (
                                    <option key={index} value={rows.sales_name} >{rows.sales_name}</option>
                                ))}
                            </Input>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType='prepend'>รหัสสินค้า</InputGroupAddon>
                            <Input type='text' placeholder='รหัสสินค้า' name='products' defaultValue={this.state.updateProducts}
                                onChange={(e) => { this.setState({ updateProducts: e.target.value }) }} />
                        </InputGroup>
                        <InputGroup>
                            <Input type='textarea' placeholder='รายละเอียด' name='details' defaultValue={this.state.updateDetails}
                                onChange={(e) => { this.setState({ updateDetails: e.target.value }) }} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType='prepend'>วันที่ประมาณการได้รับ</InputGroupAddon>
                            <Input type='date' placeholder='วันที่ประมาณการได้รับ' name='return_date'
                                defaultValue={this.state.updateReturnDate}
                                onChange={(e) => { this.setState({ updateReturnDate: e.target.value }) }} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType='prepend'>สถานะ</InputGroupAddon>
                            <Input type='select' placeholder='สถานะ' name='labStatus'
                                onChange={(e) => { this.setState({ updateLabStatus: e.target.value }) }} >
                                <option>Please Select</option>
                                <option value='Waiting'>รอส่งโรงงาน</option>
                                <option value='Picked'>ส่งโรงงานแล้ว</option>
                                <option value='Returned'>ได้รับคืนจากโรงงานแล้ว</option>
                            </Input>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType='prepend' >ลูกค้า</InputGroupAddon>
                            <Input type='text' placeholder='ลูกค้า' name='customers' defaultValue={this.state.updateCustomers}
                                onChange={(e) => { this.setState({ updateCustomers: e.target.value }) }} />
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.editLabData}>Save</Button>
                        <Button color="secondary" onClick={this.editToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    LabData: state.LabData
})

const mapDispatchToProps = (dispatch) => ({
    setLabData: (data) => {
        dispatch(setLabData(data))
    },
    setLabLoading: (loading) => {
        dispatch(setLabLoading(loading))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Lab)