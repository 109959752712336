import React, { useEffect, useState } from 'react'
import { Table, Button, Modal, Input, ModalHeader, ModalBody, ModalFooter, Media } from 'reactstrap'
import { connect } from 'react-redux'
import { getProductItemDataAll, getProductDetailDataAll } from '../../Redux/Actions/Products/ProductActions'
import { imageUrl } from '../../Services/ImageUrl'
import Axios from '../../Services/Axios'
import { FaSearch } from 'react-icons/fa'

const AddProductItem = (props) => {
    const [pdiId, setPdiId] = useState(2000)
    useEffect(() => {
        props.getProductDetailDataAll()
    }, [])
    useEffect(() => {
        props.getProductItemDataAll(pdiId)
    }, [pdiId])
    // console.log('AddProductItem : ', props)
    return (
        <div>
            <div style={{ padding: 20, margin: 10, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '100%' }} >
                    <Button>
                        <i className='fa fa-plus' style={{ fontSize: 20, marginRight: 10 }} />
                        Add Product Items
                    </Button>
                </div>
                <div style={{ width: '100%', display: 'flex', marginTop: 10 }}>
                    <Input placeholder='Product Code' style={{ wdith: '80%' }} type='text' onChange={e => setPdiId(e.target.value)} />
                    <Button style={{ width: '20%' }} onClick={() => { props.getProductDetailDataAll(pdiId) }} ><FaSearch /></Button>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }} >
                <div style={{ width: '20%', backgroundColor: '#f2f2f2' }} >
                    <ul>
                        {props.Product.productDetailDataAll.map((rows, index) =>
                            <li style={{ listStyle: 'none', margin: 5, padding: 5, cursor: 'pointer', backgroundColor: '#f9f9f9', marginLeft: -30 }} key={index}
                                onClick={() => { props.getProductItemDataAll(rows.pdd_code) }}
                            >{rows.pdd_code}</li>)}
                    </ul>
                </div>
                <div style={{ width: '80%' }}>
                    <Table>
                        <thead>
                            <tr>
                                <td>IMAGE</td>
                                {/* <td>ID</td> */}
                                <td>CODE</td>
                                <td>ARTICLE</td>
                                <td>NAME</td>
                                <td>STATUS</td>
                            </tr>
                        </thead>
                        <tbody>
                            {props.Product.productItemDataAll.map((rows, index) =>
                                <tr key={index} >
                                    <td><Media src={imageUrl.url + '/' + 'image' + '/' + 'pdi' + '/' + rows.pdi_code + '/' + rows.pdi_image} style={{ width: 100, height: undefined }} /></td>
                                    {/* <td>{rows.pdi_id}</td> */}
                                    <td>{rows.pdi_code}</td>
                                    <td>{rows.pdi_article}</td>
                                    <td>{rows.pdi_name}</td>
                                    <td><Input type='checkbox' defaultChecked={rows.pdi_status === 0 ? false : true} onChange={async (e) => {
                                        try {
                                            await Axios.patch('/pdi/update_product_item_status', { status: e.target.checked, id: rows.pdi_id })
                                        } catch (error) {
                                            console.log(error)
                                        }
                                    }} /></td>
                                </tr>)}
                        </tbody>
                    </Table>
                    <Modal>
                        <ModalHeader>

                        </ModalHeader>
                        <ModalBody>

                        </ModalBody>
                        <ModalFooter>

                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    Product: state.Product
})

export default connect(mapStateToProps, { getProductItemDataAll, getProductDetailDataAll })(AddProductItem)