import React, { Component } from 'react'
import { Chart } from 'react-google-charts'
import Axios from '../../Services/Axios'
import { localToken } from '../../Services/Document'
import { Row, Input, Container, Spinner, Table } from 'reactstrap'



export default class SellQtyByMonthBySales extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chartData: null,
            salesName: null,
            salesAll: [],
            loading: true,
            allData: []
        }
    }

    componentDidMount = async () => {
        await this.getSalesAll()
        await this.getSellQtyByMonthBySales()
    }

    getSalesAll = async () => {
        await Axios({
            mehtod: 'GET',
            url: '/getsalesall',
            headers: {
                Authorization: 'Bearer ' + localToken
            }
        }).then((res) => {
            this.setState({ salesAll: res.data.data[0] })
        }).catch((err) => {
            console.error(err)
        })
    }

    getSellQtyByMonthBySales = async (e) => {
        this.setState({ loading: true })
        await Axios({
            method: 'POST',
            url: '/sellqtybymonthbysales',
            data: {
                salesCode: e
            },
            headers: {
                Authorization: 'Bearer ' + localToken
            }
        }).then((res) => {
            var salesName
            var year
            var chartData = [['เดือน', 'จำนวนหลา'], [0, 0]]
            for (let i = 0; i < res.data.data[0].length; i += 1) {
                chartData.push([res.data.data[0][i].INV_MONTH, res.data.data[0][i].SUM_QTY])
                salesName = res.data.data[0][i].SALES_NAME
                year = res.data.data[0][i].INV_YEAR
            }
            this.setState({
                chartData: chartData,
                salesName: salesName,
                year: year,
                loading: false,
                allData: res.data.data[0]
            })
            // console.log(data)
        }).catch((err) => {
            console.error(err)
        })
    }

    numberFormat = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    render() {
        console.log(this.state)
        return (

            <Container className={"my-pretty-chart-container"}>
                <Row>
                    <h1>รายงานของ : {this.state.salesName} ปี : {this.state.year}</h1>
                </Row>
                <Row style={{ marginBottom: 10 }} >
                    <Input type='select' onChange={(e) => { this.getSellQtyByMonthBySales(e.target.value) }} >
                        <option>เลือกเซลส์</option>
                        {this.state.salesAll.map((rows, index) => (
                            <option key={index} value={'_' + rows.sales_code} >{rows.sales_code + ':' + rows.sales_name}</option>
                        ))}
                    </Input>
                </Row>
                <Row>
                    {this.state.loading ? (<Spinner size='large' />) : (
                        <Chart
                            chartType="AreaChart"
                            width="100%"
                            height="400px"
                            data={this.state.chartData}
                            title={this.state.salesName}
                            legendToggle
                            options={{
                                animation: {
                                    startup: true,
                                    easing: 'linear',
                                    duration: 1500,
                                },
                            }}
                            chartEvents={[
                                {
                                    eventName: 'animationfinish',
                                    callback: () => {
                                        console.log('Animation Finished')
                                    },
                                },
                            ]}
                        />)}
                </Row>
                <Row>
                    {this.state.loading ? (<Spinner />) : (
                        <Table striped >
                            <thead>
                                <tr>
                                    <th>YEAR</th>
                                    <th>MONTH</th>
                                    <th>QTY</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.allData.map((rows, index) => (
                                    <tr key={index} >
                                        <td>{rows.INV_YEAR}</td>
                                        <td>{rows.INV_MONTH}</td>
                                        <td>{this.numberFormat(rows.SUM_QTY.toFixed(2))}</td>
                                        <td>{this.numberFormat(rows.SUM_TOTAL.toFixed(2))}</td>
                                    </tr>))}
                            </tbody>
                        </Table>)}
                </Row>
            </Container >
        )
    }
}
