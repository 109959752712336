import { SET_LOGIN_USER_EMAIL, SET_LOGIN_USER_ID, SET_LOGIN_USER_IS_LOGIN, SET_LOGIN_USER_MOBILE, SET_LOGIN_USER_NAME } from '../../Type'

const loginReducers = (state = {
    userEmail: '',
    userId: '',
    userMobile: '',
    userIsLogin: false,
    userName: ''
}, action) => {
    switch (action.type) {
        case SET_LOGIN_USER_EMAIL:
            state = {
                ...state,
                userEmail: action.setEmail
            }
            break
            default:
        case SET_LOGIN_USER_ID:
            state = {
                ...state,
                userId: action.setId
            }
            break
        case SET_LOGIN_USER_IS_LOGIN:
            state = {
                ...state,
                userIsLogin: action.setIsLogin
            }
            break
        case SET_LOGIN_USER_MOBILE:
            state = {
                ...state,
                userMobile: action.setMobile
            }
            break
        case SET_LOGIN_USER_NAME:
            state = {
                ...state,
                userName: action.setName
            }
            break
    }
    return state
}

export default loginReducers