import { GET_PRODUCT_ITEM_DATA_ALL, GET_PRODUCT_DETAIL_DATA_ALL } from '../../Type'
import Axios from '../../../Services/Axios'

export const getProductItemDataAll = (pdiId) => async (dispatch) => {
    try {
        // console.log('Pdd Id : ',pdiId)
        const result = await Axios.get(`/pdi/get_product_item_data_by_product_detail_id?pdiId=${pdiId}`)
        // console.log(result)
        if (result) {
            dispatch({
                type: GET_PRODUCT_ITEM_DATA_ALL,
                playload: result.data.data
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export const getProductDetailDataAll = () => async (dispatch) => {
    try {
        const result = await Axios.get('/pdd')
        dispatch({
            type: GET_PRODUCT_DETAIL_DATA_ALL,
            playload: result.data.data
        })
    } catch (error) {
        console.log(error)
    }
}