import { SET_LAB_DATA, SET_LAB_LOADING } from '../../Type'

const LabReducers = (state = {
    labData: [],
    loading: false
}, action) => {
    switch (action.type) {
        case SET_LAB_DATA:
            state = {
                ...state,
                labData: action.setData
            }
            break
        case SET_LAB_LOADING:
            state = {
                ...state,
                loading: action.setLoading
            }
            break
        default:
    }
    return state
}

export default LabReducers