import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import 'font-awesome/css/font-awesome.css'
import { connect } from 'react-redux'

const Dashbord = (props) => {
    const menu = [
        {
            label: 'Admin',
            route: 'admin',
            iconName: 'fa fa-address-book'
        },
        {
            label: 'Report',
            route: 'report',
            iconName: 'fa fa-list-alt'
        },
        {
            label: 'LAB',
            route: 'lab',
            iconName: 'fa fa-flask'
        },
        {
            label: 'Sell Price',
            route: 'addproductlist',
            iconName: 'fa fa-plus-square'
        },
        {
            label: 'Sell Charts',
            route: 'charts',
            iconName: 'fa  fa-line-chart'
        },
    ]
    return (
        <Container>
            <Row style={{ flex: 1, justifyContent: 'center' }} >
                <h3>Wellcome {props.Users.userName}</h3>
            </Row>
            <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} >
                {menu.map((rows, index) =>
                    <Col key={index} style={{ margin: 10 }} md={3} xl={2} xs={12}  >
                        <Link to={`/${rows.route}`}>
                            <i className={rows.iconName} style={{ fontSize: 120 }} />
                            <h5 style={{ margin: 10 }} >{rows.label}</h5>
                        </Link>
                    </Col>)}
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    Users: state.Users
})

export default connect(mapStateToProps)(Dashbord)

