import React, { Component } from 'react'
import Axios from '../../Services/Axios'
import { Container, Modal, Card, CardBody, CardFooter, CardImg, Table, CardHeader, Row, Button, Input } from 'reactstrap'
import { imageUrl } from '../../Services/ImageUrl'
import ModalHeader from 'reactstrap/lib/ModalHeader'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalFooter from 'reactstrap/lib/ModalFooter'

export default class AddProductGroup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pdg_name: '',
            pdg_detail: '',
            pdg_image: '',
            pdg_status: '',
            imageRender: '',
            errorMessage: '',
            pdgData: [],
            pdgIsOpen: false,
            delPdgIsOpen: false,
            delId: null
        }
    }

    componentDidMount = async () => {
        await this.getProductGroup()
    }

    getProductGroup = async () => {
        await Axios({
            method: 'GET',
            url: '/pdg'
        }).then((res) => {
            this.setState({ pdgData: res.data.data })
        }).catch((err) => {
            console.error(err)
        })
    }

    productGroupToggle = () => {
        this.setState((prevState) => ({
            pdgIsOpen: !prevState.pdgIsOpen
        }))
    }

    selectFile = (e) => {
        let renderImage = new FileReader()
        let file = e.target.files[0]
        renderImage.onloadend = () => {
            this.setState({
                imageRender: renderImage.result,
                pdg_image: file,
                errorMessage: ''
            })
        }
        renderImage.readAsDataURL(file)
    }

    addProductGroup = async () => {
        if (this.state.pdg_name === '' || this.state.pdg_status === '' || this.state.FileReader === '') {
            this.setState({ errorMessage: 'กรุณกกรอกข้อมูลให้ครบ' })
        } else {
            const form = new FormData()
            form.append('pdg_id', null)
            form.append('pdg_name', this.state.pdg_name)
            form.append('pdg_name_eng', '')
            form.append('pdg_detail', this.state.pdg_detail)
            form.append('pdg_detail_eng', '')
            form.append('pdg_image', this.state.pdg_image)
            form.append('pdg_status', this.state.pdg_status)
            await Axios.post('/pdg', form).then((res) => {
                this.setState({
                    pdg_name: '',
                    pdg_detail: '',
                    pdg_image: '',
                    pdg_status: '',
                    imageRender: '',
                    errorMessage: 'Add Product Group Successful ...'
                })
                this.getProductGroup()
                setTimeout(() => {
                    this.productGroupToggle()
                }, 500)
            }).catch((err) => {
                this.setState({ errorMessage: 'Error to save data !!!' })
            })
        }
    }

    delProductGroupToggle = () => {
        this.setState((prevState) => ({
            delPdgIsOpen: !prevState.delPdgIsOpen
        }))
    }

    deleteProductGroup = async () => {
        Axios({
            method: 'DELETE',
            url: '/pdg/' + this.state.delId
        }).then((res) => {
            console.log(res)
            this.getProductGroup()
            this.delProductGroupToggle()
        }).catch((err) => {
            console.error(err)
        })
    }

    render() {
        console.log(this.state)
        return (
            <Container>
                <Row>
                    <Modal toggle={this.productGroupToggle} isOpen={this.state.pdgIsOpen} >
                        <Card>
                            <CardHeader>
                                Add Product Group
                        </CardHeader>
                            <CardBody>
                                <Input type="text" placeholder="Group Name" onChange={(e) => { this.setState({ pdg_name: e.target.value, errorMessage: '' }) }} />
                                <Input type="textarea" placeholder="Detail" onChange={(e) => { this.setState({ pdg_detail: e.target.value, errorMessage: '' }) }} />
                                <Input type="select" onChange={(e) => { this.setState({ pdg_status: e.target.value, errorMessage: '' }) }} >
                                    <option>Select Status</option>
                                    <option value={1} >Actived</option>
                                    <option value={0} >Inactive</option>
                                </Input>
                                <Input type="file" onChange={this.selectFile} />
                                {this.state.imageRender === '' ? (
                                    <h6 style={{ color: 'red' }} >No Image Selected</h6>
                                ) : (
                                        <CardImg src={this.state.imageRender} />)}
                            </CardBody>
                            <CardFooter  >
                                <Button color='success' onClick={this.addProductGroup} >Save</Button>
                                <Button onClick={this.productGroupToggle} >Cancel</Button>
                                <h6 style={{ color: 'red' }} >{this.state.errorMessage}</h6>
                            </CardFooter>
                        </Card>
                    </Modal>
                    <Modal isOpen={this.state.delPdgIsOpen} toggle={this.productGroupToggle} >
                        <ModalHeader style={{ color: 'red' }} >ต้องการลบรายการนี้ !!!</ModalHeader>
                        <ModalBody>
                            ลบรายการแล้วไม่สามารถนำกลับมาได้แล้ว
                        </ModalBody>
                        <ModalFooter>
                            <Button color='danger' onClick={() => { this.deleteProductGroup() }} >ยีนยันลบรายการ</Button>
                            <Button onClick={this.delProductGroupToggle}  >Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </Row>
                <Row>
                    <Button onClick={this.productGroupToggle} >
                        <i className='fa fa-plus' style={{ fontSize: 20, marginRight: 10 }} />
                        Add Product Group</Button>
                </Row>
                <Row>
                    <Table striped >
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Detail</th>
                                <th>Status</th>
                                {/* <th>Edit</th> */}
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.pdgData.map((rows, index) => (
                                <tr key={index}>
                                    <td>
                                        <img alt='' style={{ height: 80, width: null }} src={imageUrl.url + '/image/pdg/' + rows.pdg_image} />
                                    </td>
                                    <td>{rows.pdg_id}</td>
                                    <td>{rows.pdg_name}</td>
                                    <td>{rows.pdg_detail}</td>
                                    <td>{rows.pdg_status === 0 ? (<p>Inactive</p>) : (<p>Actived</p>)}</td>
                                    {/* <td>
                                        <i className='fa fa-pencil' style={{ color: 'blue', fontSize: 20 }} />
                                    </td> */}
                                    <td onClick={() => {
                                        this.setState({ delId: rows.pdg_id })
                                        this.delProductGroupToggle()
                                    }} ><i className='fa fa-trash' style={{ color: 'red', fontSize: 20 }} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        )
    }
}
